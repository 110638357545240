import EvaPlugin from "@eva/client/plugins/EvaPlugin";
import moment from "moment";
import Vue from "vue";
import { v4 } from "uuid";
import evaApp from "@eva/client/app";

class ToolsEvaPlugin extends EvaPlugin {

  constructor(app) {
    super(app);
  }

  getObjectHash(obj) {
    return this.getStringHash(JSON.stringify(obj));
  }

  getStringHash(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      let chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0;
    }
    return hash;
  }

  isSameObjectHash(hash, obj) {
    return hash === this.getObjectHash(obj);
  }

  isSameStringHash(hash, str) {
    return hash === this.getStringHash(str);
  }

  copyToClipboard(text) {
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected = document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
  }

  tooltipContent(content, type) {
    if (!content) {
      return null;
    }
    if (typeof content !== 'string') {
      content = content.toString();
    }
    if (!type) {
      type = 'info';
    }
    return {
      content,
      placement: 'top',
      class: `eva-tooltip--${type}`
    }
  }

  /**
   * Метод для получения уникального идентификатора
   * @returns {String}
   */
  uuid() {
    return v4();
  }

  toCamelCase(str) {
    return str
      .split('-')
      .map((value, index) => index
        ? value.substring(0, 1).toUpperCase() + value.substring(1)
        : value.substring(0, 1).toLowerCase() + value.substring(1))
      .join('');
  }

  resolveValue(value, ...args) {
    if (value == null) {
      return value;
    }
    if (typeof value === 'function') {
      value = value(...args);
    }
    return value;
  }

  handleKeys(obj, condition, action = null) {
    if (action == null) {
      action = condition;
      condition = null;
    }
    if (obj) {
      let keys = Object.keys(obj);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let value = obj[key];
        if (!condition || condition(value)) {
          action(value, key);
        }
      }
    }
  }

  async handleKeysAsync(obj, condition, action = null) {
    if (action == null) {
      action = condition;
      condition = null;
    }
    let keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let value = obj[key];
      if (!condition || condition(value)) {
        await action(value, key);
      }
    }
  }

  mapObjectOrArray(obj, keyField, handler) {
    if (!handler) {
      handler = keyField;
      keyField = 'name';
    }

    let result = [];
    if (obj) {
      if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
          let objItem = typeof obj[i] === 'string'
            ? { [keyField]: obj[i] }
            : obj[i];
          let item = handler(objItem, objItem[keyField]);
          if (item) {
            result.push(item);
          }
        }
      } else if (typeof obj === 'object') {
        let keys = Object.keys(obj);
        for (let i = 0; i < keys.length; i++) {
          let key = keys[i];
          let value = obj[key];
          if (typeof value === 'object') {
            obj[key][keyField] = key;
          }
          let item = handler(obj[key], key);
          if (item) {
            result.push(item);
          }
        }
      }
    }
    return result;
  }

  handleObjectOrArray(obj, keyField, handler) {
    if (!handler) {
      handler = keyField;
      keyField = 'name';
    }

    if (obj) {
      if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
          let objItem = typeof obj[i] === 'string'
            ? { [keyField]: obj[i] }
            : obj[i];
          handler(objItem, objItem[keyField]);
        }
      } else if (typeof obj === 'object') {
        let keys = Object.keys(obj);
        for (let i = 0; i < keys.length; i++) {
          let key = keys[i];
          let item = handler(obj[key], key);
        }
      }
    }
  }

  formatDate(ts, format = 'DD.MM.YYYY HH:mm') {
    return ts && moment(new Date(parseInt(ts))).format(format) || '';
  }

  navigate(url) {
    location.href = url;
  }

  async wait(count) {
    return new Promise(res => setTimeout(res, count));
  }

  debounce(callback, limit = 300) {
    let timeout = null;
    let state = null;

    function wrapper(args) {
      if (state) {
        state.cancel = true;
        state = null;
      }
      clear();
      timeout = setTimeout(() => invoke(args), limit);
      return wrapper;
    }

    function invoke(args) {
      timeout = null;
      state = {
        cancel: false,
        ...(args || {})
      }
      callback(state);
    }

    function clear() {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
        return true;
      }
    }

    function flush() {
      clear() && invoke();
    }

    wrapper.clear = clear;
    wrapper.flush = flush;

    return wrapper;
  }

  addOnceEventListener(el, eventName, cb, options) {
    const once = (event) => {
      cb(event)
      el.removeEventListener(eventName, once, options);
    }
    el.addEventListener(eventName, once, options);
  }

  mountComponent(component, propsData) {
    const ComponentClass = (typeof component === 'string')
      ? Vue.component(component)
      : Vue.extend(component);
    const result = new ComponentClass({
      vuetify: this.app.vuetify,
      propsData
    });
    result.$mount();
    return result;
  }

  dismountComponent(component) {
    if (component) {
      if (component.$destroy) {
        component.$destroy();
      }
      if (component.$el) {
        component.$el.remove();
        component.$el.innerHTML = '';
      }
    }
  }

  appendComponent({ parent, component, propsData }) {
    if (!parent) {
      parent = this.app.root;
    }
    let result = this.mountComponent(component, propsData);
    parent.$el.append(result.$el);
    return result;
  }

  prependComponent({ parent, component, propsData }) {
    if (!parent) {
      parent = this.app.root;
    }
    let result = this.mountComponent(component, propsData);
    parent.$el.prepend(result.$el);
    return result;
  }

  beforeComponent({ parent, component, propsData }) {
    if (!parent) {
      parent = this.app.root;
    }
    let result = this.mountComponent(component, propsData);
    parent.$el.before(result.$el);
    return result;
  }

  afterComponent({ parent, component, propsData }) {
    if (!parent) {
      parent = this.app.root;
    }
    let result = this.mountComponent(component, propsData);
    parent.$el.after(result.$el);
    return result;
  }

  scrollIntoView(parentId, elementId) {
    const p_el = document.getElementById(parentId);
    const el = document.getElementById(elementId);
    if (p_el && el) {
      const p_rect = p_el.getBoundingClientRect();
      const rect = el.getBoundingClientRect();
      if (rect.bottom < p_rect.top || rect.top > p_rect.bottom) {
        el.scrollIntoView({
          block: 'center',
          inline : 'center'
        });
      }
    }
  }

  getNestedValue(obj, path) {
    if (!obj || typeof obj !== 'object' || !path) {
      return obj;
    }
    if (path === '$currentDate') {
      return moment.utc().valueOf();
    }
    const pathKeys = path.split(".")
    let current = obj;
    for (let i = 0; i < pathKeys.length; i++) {
      current = current[pathKeys[i]];
      if (current == null) {
        return null;
      }
    }
    return current;
  }

  setNestedValue(obj, path, value, setter = (o, k, v) => o[k] = v) {
    if (!obj || !path) {
      return;
    }
    const pathKeys = path.split(".")
    let current = obj;
    for (let i = 0; i < pathKeys.length - 1; i++) {
      let key = pathKeys[i];
      if (current[key] == null) {
        setter(current, key, {});
      }
      current = current[key];
    }
    if (current) {
      setter(current, pathKeys[pathKeys.length - 1], value);
    }
    return current;
  }

  getNextValue(arr, field, prefix) {
    if (arr == null) {
      return null;
    }
    let index = arr.length + 1;
    while (true) {
      let value = `${prefix}${index}`;
      if (arr.find((i) => this.getNestedValue(i, field) === value)) {
        index++;
      } else {
        return value;
      }
    }
  }

  clone(obj) {
    if (obj == null) {
      return null;
    }
    return JSON.parse(JSON.stringify(obj));
  }

  setDeepDefaults(target, source) {
    function setArray(t, s) {
      if (!t.length) {
        for (let i = 0; i < s.length; i++) {
          let sv = s[i];
          let tv;
          if (Array.isArray(sv)) {
            t.push(tv = []);
            setArray(tv, sv);
          } else if (typeof sv === 'object') {
            t.push(tv = {});
            setObject(tv, sv);
          } else {
            t.push(sv);
          }
        }
      }
    }

    function setObject(t, s) {
      let keys = Object.keys(s);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let sv = s[key];
        if (sv == null) {
          t[key] = sv;
        } else if (Array.isArray(sv)) {
          setArray(t[key] || (t[key] = []), sv);
        } else if (typeof sv === 'object') {
          setObject(t[key] || (t[key] = {}), sv);
        } else if (t[key] == null) {
          t[key] = sv;
        }
      }
    }

    if (source == null) {
      return target;
    }

    if (target == null) {
      if (Array.isArray(source)) {
        target = [];
      } else if (typeof source === 'object') {
        target = {};
      }
    }

    if (Array.isArray(target)) {
      setArray(target, source);
    } else if (typeof target === 'object') {
      setObject(target, source);
    }

    return target;
  }

  orderBy(array, getField, order = 'ASC') {
    if (typeof getField !== 'function') {
      let field = getField;
      getField = (item) => {
        let result = item[field];
        if (typeof result === 'string') {
          result = result.toLowerCase();
        }
        return result;
      };
    }
    let sorOrder = (order || '').toString().toUpperCase() === 'ASC' ? 1 : -1;
    function compare(a, b) {
      a = getField(a);
      b = getField(b);
      return sorOrder * (a < b ? -1 : a > b ? 1 : 0);
    }
    return array.sort(compare);
  }

  groupBy(array, field) {
    return array.reduce((result, item) => {
      (result[item[field]] = result[item[field]] || []).push(item);
      return result;
    }, {});
  }

  async getImageSize(url) {
    return await new Promise((resolve) => {
      let img = new Image();
      img.onload = function() {
        resolve({
          width: this.width,
          height: this.height
        });
        delete img.src;
        img = undefined;
      }
      img.onerror = function () {
        resolve({});
      }
      img.src = url;
    });
  }

  getFloatSeparator() {
    return (1.1).toLocaleString().substring(1, 2);
  }

  areEqualById(obj1, obj2) {
    let id1 = obj1 && obj1.id;
    let id2 = obj2 && obj2.id;
    return id1 === id2;
  }

  parseFloat(value) {
    value = value.replace(/[.,]/ig, '.');
    return parseFloat(value);
  }

  async toPortal(node) {
    const portal = document.querySelector('.eva-page__fullscreen-portal');
    portal.classList.remove('disabled');
    portal.appendChild(node);
    await document.body.requestFullscreen();
  }

  async clearPortal() {
    const portal = document.querySelector('.eva-page__fullscreen-portal');
    portal.classList.add('disabled');
    if (document.fullscreenElement) {
      await document.exitFullscreen();
    }
  }

  promiseTimeout(promise, delay, reason) {
    const awaitTimeout = new Promise(
      (resolve, reject) => setTimeout(() => reject(reason), delay)
    );
    return Promise.race([promise, awaitTimeout]);
  }

  /**
   * Метод для получения текущей таймзоны пользователя
   * @returns {Number}
   */
  getTimezoneOffset() {
    return -(new Date().getTimezoneOffset() * 60);
  }

  /**
   * Метод для проверки наличия лицензии
   * @param {String} licenseCode - Код лицензции
   * @returns 
   */
  async checkLicense(licenseCode) {
    try {
      const license = await evaApp.$http.getItem(`/api/v1/softwarePackage/package/active/${licenseCode}`);
      const result = license?.vendor_code === licenseCode;

      return result;
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Метод для регистрации виджетов с проверкой лицензии
   * @param {Array} widgets - Массив виджетов
   * @param {String} licenseCode - Код лицензиии
   */
  async registerWidgets(widgets, licenseCode = null) {
    const register = () => {
      for (let widget of widgets) {
        evaApp.registerWidget(widget);
      }
    }

    if (licenseCode?.length) {
      await evaApp.$tools.checkLicense(licenseCode).then((result) => {
        if (result) {
          register();
        }
      }).catch((err) => {
        console.log(err);
      });
    } else {
      register();
    }
  }
}

export default ToolsEvaPlugin;
