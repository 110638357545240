<template>
  <div :class="col?.class" :style="col?.style">
    <span class="eva-textbox__input eva-description"> {{ col.description }} </span>
  </div>
</template>

<script>
export default {
  name: 'labelEtv',

  props: {
    col: {
      type: Object,
      default: () => { },
    },
  },
}
</script>

<style lang="less" scoped>
</style>