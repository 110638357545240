import Vue from "vue";
import EvaPlugin from "@eva/client/plugins/EvaPlugin";
import { OutsideDirective } from "@eva/client/app/EvaDirectives";
import EvaRouteManager from "./EvaRouteManager";
import { reactive } from "vue";

Vue.config.devtools = true;
Vue.config.productionTip = false;

import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';
import "@fontsource/nunito";
import '@eva/client/styles/index.less';

import VueRouter from 'vue-router';
import Vuetify from 'vuetify/lib';
import Vuelidate from 'vuelidate';
import VueMask from 'v-mask';
import { createPinia, PiniaVuePlugin } from 'pinia'


Vue.use(PiniaVuePlugin);

Vue.use(VueRouter);
Vue.use(Vuetify, {iconfont: 'mdi'});
Vue.use(Vuelidate);
Vue.use(VueMask);

import 'floating-vue/dist/style.css';
import { VTooltip } from "floating-vue";
Vue.directive('tooltip', VTooltip);
Vue.directive('click-outside', OutsideDirective);

import createPlugins from '@eva/client/plugins';
import locales from '@eva/client/locales';
import WidgetsPlugin from "../../../core/client/core/plugins/widgets/widgets-plugin";

WidgetsPlugin.install();

const widgets = [];

class EvaApp {
  constructor() {
    this.routeManager = new EvaRouteManager(this);
    this.router = new VueRouter({});
    this.settings = reactive({
      header: ''
    });

    Vue.prototype.$eva = this;
  }

  components(...components) {
    components.forEach((component) => {
      Vue.component(component.name, component)
    });
    return this;
  }

  async init() {
    try {
      this.vuetify = new Vuetify({
        theme: {
          themes: {
            dark: {
              accent: '#0260CF', 
            },
          },
        },
      });
      this.root = new Vue({
        pinia: createPinia(),
        vuetify: this.vuetify,
        router: this.router,
        render: (h) => h(this.component),
        mounted() {
          document.dispatchEvent(new CustomEvent('appMounted'));
        }
      });

      let widgets = createPlugins(this);

      Object.assign(this, widgets);

      this.$tools.handleKeys(
        this,
        (plugin) => plugin instanceof EvaPlugin,
        (plugin) => plugin.install()
      );

      const plugins = await import('@eva/plugins/index');
      await plugins.init();
      
      await this.$tools.handleKeysAsync(
        this,
        (plugin) => plugin instanceof EvaPlugin,
        (plugin) => plugin.init()
      );
      
      this.$tools.handleKeys(
        locales,
        (messages, locale) => this.$locales.register(locale, messages)
      );
    } finally {
      await this.registerWidgets();
    }
  }

  async registerWidgets() {
    widgets.forEach(({ component, componentSettings, fields, eventFilters }) => {
      Vue.prototype.$widgets.register(component, componentSettings, fields, eventFilters);
    })
  }

  registerWidget({ component, componentSettings, fields, eventFilters }) {
    widgets.push({
      component,
      componentSettings,
      fields,
      eventFilters
    });
  }

  async bootstrap(component) {
    this.component = component;
    this.root.$mount('#app');
  }
}

export default EvaApp;
