<template>
  <div
    class="eva-tabs"
    :class="{
      'eva-tabs--column': column,
    }"
  >
    <div class="eva-tabs__header" :class="getClassHeader" :style="getTabsStyle">
      <template v-for="tab in state.tabs">
        <div
          v-if="tab.visible"
          :key="tab.id"
          class="eva-tabs__header-item"
          :class="getClassesTabs(tab)"
          @click="onTabClick(tab)"
        >
          <eva-icon v-if="tab.icon" :icon="tab.icon" :color="tab.iconColor" />
          <eva-text header clipped :text="tab.header" />
        </div>
      </template>
    </div>
    <div class="eva-tabs__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "eva-tabs",

  provide() {
    return {
      state: this.state,
    };
  },

  props: {
    column: {
      type: Boolean,
      default: false,
    },
    bgc: {
      type: String,
      default: "eva-background-1",
    },
    width: {
      type: String,
      default: null,
    },
    fullsizeTabHeader: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      state: {
        current: null,
        tabs: [],
      },
    };
  },

  computed: {
    getTabsStyle() {
      return this.width ? `width: ${this.width};` : "";
    },
    getClassHeader() {
      return this.classHeader();
    },
  },

  watch: {
    "state.tabs": {
      handler() {

        if (this.state.tabs && this.state.tabs.length) {

          if (!this.state.tabs.find((t) => t.id === this.state.current)) {
            this.state.current = this.state.tabs[0].id;
          }
          
          // если выбранная вкладка имеет visible false, то выбираем первую видимую вкладку
          if (!this.state.tabs.find((t) => t.id === this.state.current && t.visible)) {
            this.state.current = this.state.tabs.find((t) => t.visible)?.id;
          }

        } else {
          this.state.current = null;
        }
      },
      deep: true,
    },
  },

  methods: {
    defaultCurrent() {
      this.state.current = this.state.tabs[0].id;
    },

    onTabClick(tab) {
      this.state.current = tab.id;
      //передача родителю имя текущей вкладки
      this.$emit("change", tab);
    },

    classHeader() {
      return this.column
        ? `eva-tabs__header--column`
        : `${this.bgc} eva-tabs__header--border-bottom`;
    },
    getClassesTabs(tab) {
      let classes = "";
      /* check active row*/
      if (!this.column) {
        classes +=
          this.state.current === tab.id
            ? `${this.bgc} eva-tabs__header-item--active eva-tabs__header-item--column`
            : "";
      }
      /* check active column*/
      if (this.column) {
        classes +=
          this.state.current === tab.id
            ? `${this.bgc} eva-tabs__header-item--active-column eva-tabs__header-item--column`
            : "";
      }

      if (this.fullsizeTabHeader) {
        classes += " eva-tabs__header-item--fullsize";
      }

      return classes;
    },
  },
};
</script>

<style lang="less">
.eva-tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &--column {
    flex-direction: row;
  }

  .eva-tabs__header {
    height: @eva-header;
    width: 100%;
    display: flex;
    flex-direction: row;

    &--column {
      flex-direction: column;

      & .eva-tabs__header-item {
        padding: 29px 20px !important;
      }
    }

    &--border-bottom {
      border-bottom: 1px solid #212d39;
    }

    .eva-tabs__header-item {
      cursor: pointer;
      font-size: 16px;
      display: flex;
      color: #acbbcb;
      align-items: center;
      padding: (@eva-padding * 2);
      border-bottom: 2px solid transparent;

      &:hover {
        border-color: #0260cf50;
      }

      &--column {
        &:hover {
          border-color: #3e4c5d;
        }
      }

      &.eva-tabs__header-item--active {
        border-color: #0094ff;
        color: #ffffff !important;
      }

      &.eva-tabs__header-item--active-column {
        border-color: #3e4c5d;
        color: #ffffff !important;
      }

      &.eva-tabs__header-item--fullsize {
        width: 100%;
        justify-content: center;
      }
    }
  }

  .eva-tabs__content {
    min-height: 0;
    height: 100%;
    width: 100%;
  }
}
</style>
