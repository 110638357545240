import Hls from "hls.js";
import EvaVideoSource from "./EvaVideoSource";

const HLS_CONFIG = {
  //TODO Включение режима дебага hls протокола (много полезного в там числе битые сегменты)
  debug: false,
  enableWorker: true,
  backBufferLength: 90,
  /*
     Настройки загрузки манифеста:
     - Таймаут на загрузку
     - настройки повтора на ошибки таймаута
     - настройки повтора на прочие ошибки
 */
  manifestLoadPolicy: {
    default: {
      maxTimeToFirstByteMs: Infinity,
      maxLoadTimeMs: 20000,
      timeoutRetry: {
        maxNumRetry: 2,
        retryDelayMs: 0,
        maxRetryDelayMs: 0,
      },
      errorRetry: {
        maxNumRetry: 2,
        retryDelayMs: 1000,
        maxRetryDelayMs: 8000,
        shouldRetry: (retryConfig, retryCount) => {
          return retryConfig.maxNumRetry >= retryCount;
        },
      },
    },
  },
  /*
      Отступ от параметра EXT-X-TARGETDURATION который определяет текущее положение стрима
      Без параметра происходит автоматическое переключение точки воспроизведения при загрузки видео по
      параметру EXT-X-TARGETDURATION
  */
  liveSyncDurationCount: 1000,
};

const MAX_RECOVERY_ATTEMPTS = 3;

class EvaHlsVideoSource extends EvaVideoSource {
  constructor(source, paused, settings) {
    super(source, paused, settings);
    this.hls = null;
  }

  getDefaultSupports() {
    return {
      screenshot: true,
      fullscreen: true,
      fit: true,
      play: true,
      pause: true,
    };
  }

  async playInternal(restart) {
    return new Promise(async (resolve, reject) => {
      try {
        if (restart) {
          const channel = await this.getChannel();
          this.hls = new Hls(HLS_CONFIG);
          this.setEventsListeners();
          const url = this.getSourceUrl(channel.url);
          //const url = "https://test-streams.mux.dev/tos_ismc/main.m3u8";
          this.hls.loadSource(url);
          this.hls.attachMedia(this.video);
          this.video.addEventListener("durationchange", resolve, {
            once: true,
          });
        } else {
          resolve();
        }
        try {
          await this.video.play();
        } catch (error) {}
      } catch (error) {
        reject(error);
      }
    });
  }

  setEventsListeners() {
    if (this.hls) {
      this.hls.on(Hls.Events.ERROR, this.onErrorRaised);
      this.hls.on(Hls.Events.FRAG_BUFFERED, this.onFragBuffered);
    }
  }

  removeEventListeners() {
    if (this.hls) {
      this.hls.off(Hls.Events.ERROR, this.onErrorRaised);
      this.hls.off(Hls.Events.FRAG_BUFFERED, this.onFragBuffered);
    }
  }

  onErrorRaised = (event, data) => {
    if (data.details === Hls.ErrorDetails.BUFFER_STALLED_ERROR) {
      this.state.isLoading = true;
    }
    if (data.fatal) {
      switch (data.type) {
        case Hls.ErrorTypes.MEDIA_ERROR:
          this.app.$logs.info(
            "EvaHlsVideoSource",
            "fatal media error encountered, try to recover"
          );
          if (this.recoveryAttempts < MAX_RECOVERY_ATTEMPTS) {
            this.recoveryAttempts++;
            this.hls.swapAudioCodec();
            this.hls.recoverMediaError();

          } else {
            this.app.$logs.error("EvaHlsVideoSource", "Max recovery attempts reached");
            this.state.isError = true;
            this.state.isLoading = false;
            this.clearInternal();
            this.recoveryAttempts = 0;
          }
          break;
        case Hls.ErrorTypes.NETWORK_ERROR:
          if (data.details === Hls.ErrorDetails.FRAG_LOAD_ERROR) {
            this.app.$logs.error("Failed segment:", data.frag.relurl);
            this.video.currentTime = data.frag.start + data.frag.duration + 0.1;
            break;
          }
          this.state.isError = true;
          this.state.isLoading = false;
          this.app.$logs.error(
            "EvaHlsVideoSource",
            "fatal network error encountered",
            data
          );
          this.clearInternal();
          break;
        default:
          this.state.isError = true;
          this.state.isLoading = false;
          this.app.$logs.error(
            "EvaHlsVideoSource",
            "fatal media error encountered"
          );
          this.clearInternal();
          break;
      }
    }
  };

  onFragBuffered = (event, data) => {
    this.state.isLoading = false;
  };

  clearInternal() {
    if (this.hls) {
      this.removeEventListeners();
      this.hls.destroy();
      this.hls = null;
    }
  }

  async getChannel() {
    const { stream_id, begin_time, end_time, timeout } = this.source;
    return await this.app.$http.post(
      `/api/v1/videohubservice/integrationapi/archive/${stream_id}`,
      {
        begin_time,
        end_time,
        timeout,
      }
    );
  }

  getSourceUrl(url) {
    return url.replace("webrtc", "hls/live/index.m3u8");
  }
}

export default EvaHlsVideoSource;
