<template>
  <div :class="col?.class" :style="col?.style">
    <span class="eva-textbox__label eva-foreground-3"> {{ col.label }} </span>
    <span class="eva-textbox__input eva-description" :class="col?.descriptionColor">
      {{ col?.description ? col.description : '-' }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'labelDescription',

  props: {
    col: {
      type: Object,
      default: () => { },
    },
  },

}
</script>

<style lang="less" scoped>
.etv-rowLabel {
  color: #fff
}

.eva-label {
  color: #7B8B9D;
}

.eva-description {
  color: #E4E9EF;
}

.green-1{
  color:  #3cb17e;
}

.red-1{
  color:  #F44336;
}
</style>