import { defineStore } from "pinia";

export const myOrganizationStore = defineStore({
  id: 'myOrganizationStore',
  state: () => ({
    refreshTable: false
  }),
  actions: {

    setRefreshTable(value) {
      this.refreshTable = value;
      //может использоваться в нескольких местах независящих друг от друга, 
      //для корректного обновлдения вставлен settimeout
      setTimeout(() => {
        this.refreshTable = false;
      }, 1000);
    },

  }
})




