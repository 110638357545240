<template>
  <div class="eva-tree-node">
    <div
      :id="item.el_id"
      class="eva-tree-node__item"
      :class="{ 'eva-tree-node__item--selected': item.isSelected }"
      :draggable="!!state.drag"
      @dragstart="onDragStart"
      @dragover="onDragOver"
      @drop="onDrop"
      @click.stop.prevent="onClick"
    >
      <span
        v-if="item.icon"
        class="eva-tree-node__item-arrow"
      >
        <span
          class="mdi"
          :class="item.icon"
          :style="{ color: item.color }"
          @click.stop.prevent="item.isOpen = !item.isOpen"
        ></span>
      </span>

      <span class="textRow" :style="styles">{{ item.name }}</span>
<!--       <eva-data-name
          v-model="item.name"
          :color="styles"
      /> -->

      <eva-spacer/>
      <div v-if="item.icons" class="eva-tree-node__item-icons">
        <span
          v-for="icon in item.icons"
          :key="icon.icon"
          class="mdi"
          :class="icon.icon + (icon.disabled === true ? ' eva-tree-node__item-icon--disabled' : '')"
          v-tooltip="$eva.$tools.tooltipContent(icon.tooltip)"
          @click.stop.prevent="onIconClick(icon)"
        ></span>
      </div>
    </div>
    <div v-if="item.nodes" class="eva-tree-node__children">
      <eva-tree-node
        v-for="(child, index) in item.nodes"
        :key="index"
        :item="child"
        :state="state"
        ref="nodes"
      />
    </div>
    <eva-intersect
        v-if="item.hasPagination && !item.isLoading && item.nodes && item.nodes.length"
        @enter="item.loadNext()"
    />
  </div>
</template>

<script>
export default {
  name: 'eva-tree-node',

  props: {
    item: {},
    state: {}
  },

  computed:{
    styles() {
      return {
        color: this.item.color
      }
    }
  },

  methods: {
    onDragStart(e) {
      if (!this.state.drag) {
        return;
      }
      this.$eva.$dragndrops.beginDragMove(e, this.state.drag, {
        id: this.item.id,
        type: this.item.type,
        name: this.item.name
      });
    },
    onDragOver(e) {
      this.$eva.$dragndrops.allowDrop(e, this.state.drop);
    },
    async onDrop(e) {
      let res = this.$eva.$dragndrops.endDrop(e, this.state.drop);
      let evaTreeNode = res['eva-tree-node'];
      if (evaTreeNode) {
        if (
          evaTreeNode.id !== this.item.id &&
          this.state.drop['eva-tree-node'] &&
          await this.state.drop['eva-tree-node'](evaTreeNode, this.item)
        ) {
          this.state.removeNodes(evaTreeNode.id);
          await this.state.reloadNodes(this.item.id);
        }
        return;
      }
      const keys = Object.keys(res);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (key === 'eva-tree-node') {
          continue;
        }
        if (this.state.drop[key]) {
          await this.state.drop[key](res[key], this.item);
        }
      }
    },
    onIconClick(icon) {
      if (!icon.handler || icon.disabled === true) {
        return;
      }
      icon.handler(this.item.data);
    },
    async onClick() {
      if (!await this.state.beforeSelect()) {
        return;
      }
      this.item.isSelected = !this.item.isSelected;
    }
  }
}
</script>

<style lang="less">
.eva-tree-node {
  width: 100%;
  min-width: fit-content;

  .eva-tree-node__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    border-radius: 6px;
    height: 40px;
    &:hover {
      /*background-color: #E5F1FF;*/
      cursor: pointer;
    }
    &.eva-tree-node__item--selected {
      color: white;
      background-color: #0260CF!important;
    }
    .eva-tree-node__item-arrow {
      font-size: 18px;
      width: 28px;
      height: 28px;
      cursor: pointer;
      border-radius: 6px;
      border: 1px solid transparent;
      display: flex;
      flex-shrink: 0;
      &:hover {
        /*border: 1px solid #d2e7ff;
        background-color: #E5F1FF;*/
      }
      span {
        margin: auto;
      }
    }
    .eva-tree-node__item-icons {
      display: flex;
      flex-direction: row;
      flex-shrink: 0;
      .mdi {
        font-size: 18px;
        width: 28px;
        height: 28px;
        display: flex;
        flex-shrink: 0;
      }
      .eva-tree-node__item-icon--disabled {
        opacity: 0.5;
      }
    }
  }
  .eva-tree-node__children {
    padding-left: 16px;
  }

  .textRow {
    white-space: nowrap;
  }
}
</style>
