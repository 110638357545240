<template>
  <div :class="col?.class" :style="col?.style">
    <span class="eva-textbox__label eva-foreground-3"> {{ col.label }} </span>
    <div class="elabelfunctional-description">
      <span class="eva-textbox__input eva-description" :class="col?.descriptionColor">
        {{ col?.description ? col.description : '-' }}
      </span>
      <span class="cursor-pointer" :class="col?.hrefFunction?.icon" @click="clickFunction" v-tooltip="col?.tooltip"></span>
    </div>

  </div>
</template>

<script>
/*  
Example*
    type: "labelFunctional",
    description: `Задание: ${!!this.model.numberTask ? this.model.numberTask : "-"}`,
    tooltip: "Открыть задание",
    hrefFunction: {
      icon: 'mdi mdi-open-in-new',
      function: async () => { }
    }
 */

  export default {
    name: 'labelFunctional',

    props: {
      col: {
        type: Object,
        default: () => { },
      },
    },
    methods: {
      clickFunction() {
        if (typeof this.col?.hrefFunction?.function === 'function') {
          this.col.hrefFunction.function();
        } else {
          console.error('Ошибка: hrefFunction.function не является функцией');
        }
      }
    }

  }
</script>

<style lang="less" scoped>
.elabelfunctional-description {
  display: flex;
  align-items: left;
  flex-direction: row;

  .cursor-pointer {
    cursor: pointer;
  }
}



.etv-rowLabel {
  color: #fff
}

.eva-label {
  color: #7B8B9D;
}

.eva-description {
  color: #E4E9EF;
}

.green-1 {
  color: #3cb17e;
}

.red-1 {
  color: #F44336;
}
</style>