<template>
  <div
    v-if="isActive || initialized"
    class="eva-tab"
    :style="{ display: isActive ? '' : 'none' }"
  >
    <slot />
  </div>
</template>

<script>
import { computed } from "vue";
import { v4 } from "uuid";

export default {
  name: "eva-tab",

  inject: ["state"],

  props: {
    header: {
      type: String,
    },
    icon: {
      type: String,
    },
    iconColor: {
      type: String,
    },
    idTab: {
      type: String,
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      id: v4(),
      initialized: false,
    };
  },

  computed: {
    isActive() {
      return this.state.current === this.id;
    },
  },

  mounted() {
    this.state.tabs.push({
      id: this.id,
      header: this.header,
      icon: this.icon,
      iconColor: this.iconColor,
      idTab: this.idTab,
      visible: computed(() => this.visible),
    });
  },

  watch: {
    isActive: {
      handler(value) {
        if (value) {
          this.initialized = true;
        }
      },
      immediate: true,
    },
    icon(value) {
      let tab = this.state.tabs.find((t) => t.id === this.id);
      tab.icon = value;
    },
    iconColor(value) {
      let tab = this.state.tabs.find((t) => t.id === this.id);
      tab.iconColor = value;
    },
  },
};
</script>

<style lang="less">
.eva-tab {
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>
