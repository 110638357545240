const LAST_DESKTOP_ID = "LAST_DESKTOP_ID";
const Z_INDEXES = Object.freeze({
  panelStart: 1101,
  panelDialog: 2200, // из расчета, что z-index боковых панелей, в т.ч. сайдбара и панели с виджетами, равен 2000
  templateDialog: 1300,
});

const LICENSE_ERROR_MESSAGE =
  "Данный раздел заблокирован. Отсутствует лицензия";

const LICENSES = Object.freeze({
  telegram: "ESM-Notification-T",
});

export { LAST_DESKTOP_ID, Z_INDEXES, LICENSE_ERROR_MESSAGE, LICENSES };
