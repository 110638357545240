<template>
  <eva-layout column fill no-gap class="eva-tree-registry" transparent @dragover.native="onDragOver"
    @drop.native="onDrop"><!-- evatreeregistr -->
    <!-- block search -->
    <div v-if="showHeader" class="eva-tree-header">
      <eva-text v-if="showCaption" header :text="caption" />
      <eva-layout row no-gap class="eva-tree-header__tools" transparent>
        <eva-textbox v-if="showContext" v-model="context" placeholder="Поиск" icon="mdi-magnify"
          class="eva-tree-header__context" />
        <eva-spacer />
        <!-- search button & showList -->
        <eva-command-list v-if="state.listCommands.length" :commands="state.listCommands" ref="listCommands" />
        <!-- :model="selected" -->
        <eva-command-list v-if="state.itemCommands.length" :commands="state.itemCommands" ref="itemCommands" />
      </eva-layout>
    </div>

    <!-- block tree -->
    <eva-layout v-if="state.root.nodes" :id="state.root.id" column fill no-gap scroll class="eva-tree-inner" transparent
      :style="{ width: settings.size, flex: settings.size ? '0 0 auto' : undefined, margin: '1px 0px 40px 1px' }">
      <!-- childrens -->
      <eva-tree-node-registry v-for="child in state.root.nodes" :key="child.id" :item="child" :state="state" :level="0"
        ref="nodes" @click="onClick" :options="settings"/>

      <!-- block loader next pages -->
      <eva-intersect
        v-if="state.root.hasPagination && !state.root.isLoading && state.root.nodes && state.root.nodes.length"
        @enter="state.root.loadNext()" />
    </eva-layout>

  </eva-layout>
</template>

<script>
/* special - tree for registry devices */
import { reactive, computed, ref, watch } from "vue";
import { v4 } from "uuid";
import { treeRemoveStore } from '@piniaStores';
const storeTreeRemove = treeRemoveStore();

const DEFAULT_OFFSET = 20;

function createState(app, emit, options) {
  const listCommands = ref([]);
  const itemCommands = ref([]);
  const selected = ref(null);
  const settings = ref(null);
  const context = ref('');
  const rootItem = { id: v4() };
  const root = ref(createNode(rootItem));

  function createNode(item, parent) {
    const id = computed(() => currentItem.value.id);
    const type = computed(() => currentItem.value.type);
    const el_id = computed(() => rootItem.id + currentItem.value.id);
    let isRoot = computed(() => item === rootItem);
    let isLoading = ref(false);
    let isLoadComplete = ref(false);
    const hasPagination = computed(() => settings.value && settings.value.pagination === true);
    let isOpen = ref(false);
    let isSelected = computed({
      get: () => selected.value === item.id,
      set: (value) => settings.value.icons !== false
        ? selected.value = value
          ? item.id
          : null
        : null
    });//
    let currentItem = ref(item);
    let name =
      typeof currentItem.value.name === 'object'
        ? currentItem.value.name.ru
        : currentItem.value.name;

    let classColor = currentItem.value.classColor;
    let iconAlert = currentItem.value.iconAlert;

    let icon = computed(() => {
      if (settings.value.icons !== false) {
        /* check device */
        if (isLoading.value) {
          return 'mdi-spin mdi-autorenew';
        } else if (currentItem.value.icon) {
          return currentItem.value.icon;
        } else if (isOpen.value) {
          return 'mdi-chevron-down';
        } else {
          return 'mdi-chevron-right';
        }
      }
    });

    let color = computed(() => currentItem.value.color);
    let icons = computed(() => app.$tools.resolveValue(() => settings.value.getIcons && settings.value.getIcons(currentItem.value)));
    let rightIcons = currentItem.value.rightIcons;
    let nodes = ref([]);
    let keyyy = 0;

    let filterCheck = computed(() => currentItem.value.filterCheck);

    let reloadKey;
    async function reload() {
      if (!settings.value) {
        return;
      }
      const currentReloadKey = reloadKey = v4();
      try {
        isLoading.value = true;
        isLoadComplete.value = false;
        let items;

        if (isRoot.value) {
          items = await settings.value.loadRoot(context.value, 0, DEFAULT_OFFSET);
        } else {
          items = await settings.value.loadChildren(currentItem.value, context.value, 0, DEFAULT_OFFSET);
        }
        //смотрим, если реультат вывелся меньше, чем отображаем, тогда у нас подгрузка блокируется
        isLoadComplete.value = items.length < 20 ? true : false;
        if (reloadKey === currentReloadKey) {
          //nodes.value.splice(0, nodes.value.length);

          if (items) {
            nodes.value.push(...items.map((i) => createNode(i, node)));
          }
        }
      } catch (error) {
        app.$logs.error(
          'eva-tree-registry',
          'Произошла ошибка при перезагрузке дочерних элементов',
          error
        );
      } finally {
        if (reloadKey === currentReloadKey) {
          isLoading.value = false;
        }
      }
    }

    async function searchContext(options={}) {

      let items;
      if (context.value == '' && !storeTreeRemove.filterCheck) {
        /* clear context */
        items = await settings.value.loadRoot(context.value, 0, DEFAULT_OFFSET);
      } else {
        items = await settings.value.loadSearch(context.value, 0, DEFAULT_OFFSET);
      }

      if (options?.from === 'okTypeDevice') {
        //custom search
        items = await settings.value.loadSearch(context.value, 0, DEFAULT_OFFSET);
      }

      if (options?.from === 'okTypeDeviceClear') {
        //custom search
        items = await settings.value.loadRoot(context.value, 0, DEFAULT_OFFSET);
      }

      if (items) {
        /* not clear context */
        nodes.value.length = 0;
        nodes.value.push(...items.map((i) => createNode(i, node)));
      }

    }

    async function loadNext() {

      if (isLoadComplete.value || !hasPagination.value) {
        return;
      }
      try {
        isLoading.value = true;

        let items;
        if (isRoot.value) {
          items = await settings.value.loadRoot(context.value, nodes.value.length, DEFAULT_OFFSET);
        } else {
          items = await settings.value.loadChildren(currentItem.value, context.value, nodes.value.length, DEFAULT_OFFSET);
        }

        if (items && items.length) {
          nodes.value.push(...items.map((i) => createNode(i, node)));
        } else {
          isLoadComplete.value = true;
        }
      } catch (error) {
        app.$logs.error(
          'eva-tree-registry',
          'Произошла ошибка при перезагрузке дочерних элементов',
          error
        );
      } finally {
        isLoading.value = false;
      }
    }
    /* _______ */
    async function reloadSelectedItem(id) {

      if (!storeTreeRemove.filterCheck && context.value === '') {
        let node = findNode(id);
        if (node) {
          await node.reloadItem();
        }
      }else{
        searchContext();
      }

    }

    async function reloadItem() {
      if (isLoading.value || !settings.value) {
        return;
      }
      try {
        //релоад чилдренов
        isLoading.value = true;
        let newNodes = await settings.value.loadChildren(currentItem.value);
        nodes.value = [];
        nodes.value.push(...newNodes.map((i) => createNode(i, node)));
      } catch (error) {
        app.$logs.error(
          'eva-tree-registry',
          'Произошла ошибка при перезагрузке данных элемента',
          error
        );
      } finally {
        isLoading.value = false;
      }
    }

    function remove() {
      if (parent) {
        let index = parent.nodes.indexOf(node);
        if (index >= 0) {
          parent.nodes.splice(index, 1);
        }
      }
    }

/*     watch(isSelected, (value) => {
      if (value) {
        if (!isOpen.value) {
          isOpen.value = true;
        }
        app.$tools.scrollIntoView(rootItem.id, el_id.value);
      }
    }); */
    watch(isOpen, (value) => {

      if (value) {
        reload();
      } else {
        nodes.value.splice(0, nodes.value.length);
      }
    });
    if (isSelected.value) {
      isOpen.value = true;
      reload();
    }

    const node = Object.seal(reactive({
      id,
      type,
      el_id,
      isOpen,
      isSelected,
      isRoot,
      isLoading: computed(() => isLoading.value),
      hasPagination: computed(() => hasPagination.value),
      name,
      iconAlert,
      classColor,
      icon,
      color,
      icons,
      rightIcons,
      nodes: computed(() => nodes.value),
      reload,
      searchContext,
      loadNext,
      reloadSelectedItem,
      reloadItem,
      remove,
      data: computed(() => currentItem.value),
    }));
    return node;
  }

  function findNode(id, nodes = null) {

    if (!nodes) {
      nodes = root.value.nodes;
    }

    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].id === id) {
        return nodes[i];
      }
    }
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].nodes != null) {
        let result = findNode(id, nodes[i].nodes);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  function findParent(id, node) {
    if (!node) {
      node = root.value;
    }
    for (let i = 0; i < node.nodes.length; i++) {
      if (node.nodes[i].id === id) {
        return node;
      }
    }
    for (let i = 0; i < node.nodes.length; i++) {
      if (node.nodes[i].nodes != null) {
        let result = findParent(id, node.nodes[i]);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  function removeNodes(...ids) {
    for (let i = 0; i < ids.length; i++) {
      let node = findNode(ids[i]);
      if (node) {
        node.remove();
      }
    }
  }

  async function reloadNodes(...ids) {
    for (let i = 0; i < ids.length; i++) {
      let node = findNode(ids[i]);
      if (node) {
        await node.reload();
      }
    }
  }



  async function reloadItems(...ids) {

    for (let i = 0; i < ids.length; i++) {
      let node = findNode(ids[i]);

      if (node) {
        await node.reloadItem();
      }
    }
  }

  function createCommands(settings, name) {
    let commands = settings && settings.commands && settings.commands[name] || [];
    return app.$tools.mapObjectOrArray(commands, (command) => {
      if (!command.prefix) {
        command.prefix = `${settings.prefix}.commands`;
      }
      return app.$commands.create(command);
    });
  }

  async function beforeSelect() {
    if (settings.value && settings.value.beforeSelect) {
      return await settings.value.beforeSelect();
    } else {
      return true;
    }
  }

  watch(settings, (value) => {
    listCommands.value.splice(0, listCommands.value.length);
    itemCommands.value.splice(0, itemCommands.value.length);
    root.value = createNode(rootItem);
    if (value) {
      listCommands.value = createCommands(value, 'list');
      itemCommands.value = createCommands(value, 'item');
      root.value.reload(); // init tree
    }
  });

  watch(context, () => {
    //root.value.reload();
    root.value.searchContext();
  });

  if (options) {
    settings.value = options;
  }

  return Object.seal(reactive({
    root: computed(() => root.value),
    context,
    listCommands: computed(() => listCommands.value),
    itemCommands: computed(() => itemCommands.value),
    drag: computed(() => settings.value && settings.value.drag),
    drop: computed(() => settings.value && settings.value.drop),
    findNode,
    findParent,
    reloadItems,
    reloadNodes,
    removeNodes,
    beforeSelect,
    selected,
    settings
  }));
}

export default {
  name: 'eva-tree-registry',

  model: {
    prop: 'selected',
    event: 'selected:update'
  },

  props: {
    selected: {},
    settings: {}
  },

  data() {
    return {
      error: null,
      loading: false,
      items: null,
      context: '',
      state: createState(this.$eva, (...args) => this.$emit(...args)),
      componentKey: 0
    }
  },

  watch: {
    settings: {
      handler(value) {
        this.state.settings = value;
      },
      immediate: true
    },
    selected: {
      handler(value) {
        this.state.selected = value;
      },
      immediate: true
    },
    'state.selected': {
      handler(value) {
        this.$emit('selected:update', value);
      },
      immediate: true
    },
    context(value) {
      this.state.context = value;
    }
  },

  computed: {
    showHeader() {
      return this.settings.header !== false;
    },
    showCaption() {
      return this.settings.caption !== false;
    },
    caption() {
      return `${this.$eva.$t(`$t.${this.settings.prefix}.caption`)}`;
    },
    showContext() {
      return this.settings.context !== false;
    }
  },

  methods: {
    async onClick(item) {
      await this.state.settings.onClick(this.state, item);
    },

    onDragOver(e) {
      this.$eva.$dragndrops.allowDrop(e, this.state.drop);
    },
    async onDrop(e) {
      let res = this.$eva.$dragndrops.endDrop(e, this.state.drop);
      let evaTreeNode = res['eva-tree-node'];
      if (evaTreeNode) {
        if (
          this.state.drop['eva-tree-node'] &&
          await this.state.drop['eva-tree-node'](evaTreeNode, null)
        ) {
          this.state.removeNodes(evaTreeNode.id);
          await this.state.root.reload();
        }
      }
    }
  }

}
</script>

<style lang="less">
.eva-tree-registry {
  display: flex;
  flex-direction: column;
  /*color: #3E4C5D;*/
  min-height: 0;
  height: 100%;

  .selectBlock {
    background-color: #2A3746;

    .childBlock {
      background-color: #324459;
    }

  }

  .eva-tree-header {
    padding: (@eva-padding * 2) (@eva-padding * 2) @eva-padding;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: column;
    gap: @eva-padding;

    .eva-tree-header__context {
      width: 100%;
    }

    .eva-tree-header__tools {
      height: @eva-header;
      align-items: center;
    }
  }

  .eva-tree-inner {
    padding: 1px 3px 50px 1px;
    /* padding: 2px (@eva-padding * 2); */
  }
}
</style>
